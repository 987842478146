// TODO: add non-distance cost ?
import { forwardRef, useEffect, useState } from "react";
// Firebase
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Datepicker
// Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// User auth
import { useUserAuth } from "../context/UserAuthContext";
// Style
import "../style/Form.scss";

const Form = ({
  setCurrentData,
  setNotificationData,
  setDraftData,
  startDate,
  setStartDate,
  reasonVal,
  setReasonVal,
  startOdometer,
  setStartOdometer,
  endOdometer,
  setEndOdometer,
  isChecked,
  setChecked,
}) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const { user } = useUserAuth();
  useEffect(() => {
    if (user.uid) {
      setUserLoaded(true);
    }
  }, [user]);

  const submitForm = (e) => {
    e.preventDefault();
    // FULL
    if (e.nativeEvent.submitter.name === "submit-full") {
      if (startOdometer === 0 || endOdometer === 0) {
        console.error("Must input odometer readings");
        setNotificationData({
          text: "Must enter odometer readings",
          colour: "var(--red)",
        });
        return;
      }
      let distance = endOdometer - startOdometer;
      let uid = user.uid;
      setCurrentData({
        startDate,
        startOdometer,
        endOdometer,
        reasonVal,
        isChecked,
        distance,
        uid,
      });
    } else if (e.nativeEvent.submitter.name === "submit-draft") {
      // DRAFT
      if (startOdometer === 0) {
        console.error("Must input at least first odometer reading");
        setNotificationData({
          text: "Must input at least first odometer reading",
          colour: "var(--red)",
        });
        return;
      }
      setDraftData({
        startDate,
        startOdometer,
        endOdometer,
        reasonVal,
        isChecked,
        uid: user.uid,
      });
    }
  };
  // Clear draft
  const clearDraft = async () => {
    const docRef = doc(db, `users/${user.uid}/drafts`, "draft-entry");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await deleteDoc(doc(db, `users/${user.uid}/drafts`, "draft-entry"))
        .then(() => {
          setStartDate(new Date());
          setStartOdometer(0);
          setEndOdometer(0);
          setReasonVal("Deliveries");
          setNotificationData({
            text: "Draft cleared from database",
            colour: "var(--red)",
          });
        })
        .catch((err) => console.error(err));
    } else {
      setNotificationData({
        text: "No draft saved",
        colour: "var(--yellow)",
      });
    }
  };

  // Custom date picker
  const CustomDate = forwardRef(({ value, onClick }, ref) => (
    <button
      className="custom-date-input btn"
      onClick={onClick}
      ref={ref}
      type="button"
    >
      {value}
    </button>
  ));

  return (
    <>
      {userLoaded && (
        <div className="form-container" id="add-full-entry">
          <div className="form-header">
            <h1 className="form-title">Add new entry:</h1>
            <p>
              Logged in as: <span>{user.email}</span>
            </p>
          </div>
          <form className="form-inputs" onSubmit={(e) => submitForm(e)}>
            <div className="date-input-container tooltip">
              <DatePicker
                // shouldCloseOnSelect={true}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDate />}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <span className="tooltip-text">Date</span>
            </div>
            <div className="start-odometer tooltip">
              <input
                type="number"
                name="start-odometer"
                id="start-odometer"
                className="start-odometer-input"
                value={startOdometer}
                onChange={(e) => setStartOdometer(e.currentTarget.value)}
                placeholder="Start"
                min="0"
              />
              <span className="tooltip-text">Start odometer</span>
            </div>
            <div className="end-odometer tooltip">
              <input
                type="number"
                name="end-odometer"
                id="end-odometer"
                className="end-odometer-input"
                value={endOdometer}
                onChange={(e) => setEndOdometer(e.currentTarget.value)}
                placeholder="End"
                min="0"
              />
              <span className="tooltip-text">End odometer</span>
            </div>
            {/* Distance | Start/end odometer readings */}
            <div className="reason-input-container tooltip">
              <input
                type="text"
                name="reason"
                id="reason"
                value={reasonVal}
                placeholder="Deliveries"
                onChange={(e) => setReasonVal(e.currentTarget.value)}
              />
              <span className="tooltip-text">Journey reason</span>
              {/* Reason | Give text input but auto select: Deliveries (?) */}
            </div>
            <div className="submit-container">
              <div className="second-trip">
                <h3>Second trip today?</h3>
                <input
                  type="checkbox"
                  name="secondTrip"
                  id="secondTrip"
                  onChange={(e) => setChecked(e.currentTarget.checked)}
                />
              </div>
              <div className="buttons">
                <div className="draft-btns">
                  <button
                    type="submit"
                    name="submit-draft"
                    className="form-submit-btn btn yellow"
                  >
                    Draft
                  </button>
                  <button
                    className="clear-draft btn red"
                    type="button"
                    onClick={clearDraft}
                  >
                    Clear draft
                  </button>
                </div>
                <button
                  type="submit"
                  name="submit-full"
                  className="form-submit-btn btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Form;
