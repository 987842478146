// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Firebase
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore/lite";
import { db } from "../firebase";
// auth
import { useUserAuth } from "../context/UserAuthContext";
// Components
import Dates from "./Dates";

// Style/Icons
import "../style/Data.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { getDoc } from "firebase/firestore";

const Data = ({
  flDates,
  setflDates,
  setCurrentData,
  setNotificationData,
  dFT,
}) => {
  const [entriesData, setEntriesData] = useState([]);
  // Modal state
  const [modalActive, setModalActive] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalRefresh, setModalRefresh] = useState(0);

  const [totalDistance, setTotalDistance] = useState(0);
  const [ratio, setRatio] = useState(0);
  const [maxDistance, setMaxDistance] = useState(0);
  const [shortestDistance, setShortestDistance] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);
  const [datesUpdated, setDatesUpdate] = useState(false);

  const { user } = useUserAuth();
  //
  // GET DATA
  //

  // DATES
  useEffect(() => {
    if (!user.uid) return;
    const getDates = async () => {
      const firstDateRef = doc(db, `users/${user.uid}/firstLastDates`, "first");
      const first = await getDoc(firstDateRef)
        .then((doc) => doc.data())
        .catch((err) => console.log(err));
      const lastDateRef = doc(db, `users/${user.uid}/firstLastDates`, "last");
      const last = await getDoc(lastDateRef)
        .then((doc) => doc.data())
        .catch((err) => console.log(err));
      const dateSnapshot = { first, last };
      return dateSnapshot;
    };
    getDates()
      .then((data) => setDates(data))
      .catch((err) => console.error(err));
    setDatesUpdate(false);
    // eslint-disable-next-line
  }, [user, datesUpdated]);

  // ENTRIES
  useEffect(() => {
    if (!user) return;
    const getEntries = async () => {
      const collectionRef = collection(db, `users/${user.uid}/entries`);
      // Query params
      const q = query(collectionRef, orderBy("date", "asc"));

      const snapshot = await getDocs(q);
      const list = snapshot.docs.map((doc) => doc.data());
      return list;
    };
    getEntries()
      .then((data) => setEntriesData(data))
      .catch((err) => console.error(err));
  }, [modalRefresh, user]);

  // Set stats
  useEffect(() => {
    // Total distance
    let total = 0;
    entriesData.forEach((i) => {
      total = total + i.distance;
    });
    setTotalDistance(total);

    // Ratio
    if (getLength(entriesData) > 0) {
      const firstOdometer = entriesData[0].startOdometer;
      const lastOdometer = entriesData[entriesData.length - 1].endOdometer;
      const totalDriven = lastOdometer - firstOdometer;
      const preRatio = (totalDistance / totalDriven) * 100;
      const neatRatio = Math.round(preRatio * 100) / 100;
      setRatio(neatRatio);
    }

    // Largest trip
    const distances = [];
    entriesData.forEach((i) => {
      distances.push(i.distance);
    });
    const max = Math.max(...distances);
    setMaxDistance(max);

    // Shortest trip
    const min = Math.min(...distances);
    setShortestDistance(min);

    // Savings
    const rate = totalDistance * 0.83;
    let savings = rate * (ratio / 100);
    savings = Math.round(savings * 100) / 100;
    setTotalSavings(savings);

    // Total trips
    const trips = distances.length;
    setTotalTrips(trips);
  }, [entriesData, totalDistance, ratio]);

  const getLength = (obj) => {
    if (obj) {
      return Object.keys(obj).length;
    }
  };

  const setDates = (data) => {
    let dateObj = {};
    if (data.first) {
      dateObj.first = data.first.date;
    }
    if (data.last) {
      dateObj.last = data.last.date;
    }
    setflDates(dateObj);
  };

  //
  //  EDITING DOCS
  //

  // DELETE
  const handleDeleteEntry = (date, reason) => {
    setModalData({ date: date, reason: reason });
    setModalActive(true);
  };
  const handleModalReply = (res) => {
    if (res === "no") {
      setModalActive(false);
      setModalData({});
    }
    if (res === "yes") {
      deleteEntry();
    }
  };
  const deleteEntry = async () => {
    // let obj = entriesData.find((o) => dFT(o.date) === modalData);
    let date = modalData.date;
    let newDate = date.replace("/", " ");
    newDate = newDate.replace("/", " ");

    await deleteDoc(doc(db, `users/${user.uid}/entries`, newDate))
      .then(() => {
        setModalRefresh(1);
        setTimeout(() => {
          setModalRefresh(0);
        }, 2000);
        setModalActive(false);
        setModalData({});
        setNotificationData({
          text: "Entry deleted",
          colour: "var(--red)",
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="data-section">
      {modalActive === true ? (
        <div className="entry-modal">
          <div className="entry-modal-body">
            <h3>Delete entry?</h3>
            <h4>
              {modalData.date} - {modalData.reason}
            </h4>
            <div className="entry-modal-buttons">
              <button
                className="entry-modal-no"
                onClick={() => handleModalReply("no")}
              >
                No
              </button>
              <button
                className="entry-modal-yes"
                onClick={() => handleModalReply("yes")}
              >
                Yes
              </button>
            </div>
          </div>
          <div className="screen"></div>
        </div>
      ) : null}
      <div className="data-header">
        <h1>Data</h1>
        <Dates
          flDates={flDates}
          setFlDates={setflDates}
          dFT={dFT}
          setDatesUpdate={setDatesUpdate}
        />
      </div>

      <div className="data-main-container">
        <div className="data-info-container">
          <div className="data-totals-container">
            <h2>Stats</h2>
            <div className="data-stats-container">
              <ul>
                <li>
                  <p>Total distance:</p>
                  <p>{totalDistance}km</p>
                </li>
                <li>
                  <p>Work usage:</p>
                  <p>{ratio}%</p>
                </li>
                <li>
                  <p>Highest:</p>
                  <p>{shortestDistance}km</p>
                </li>
                <li>
                  <p>Lowest:</p>
                  <p>{maxDistance}km</p>
                </li>
                <li>
                  <p>Total shifts:</p>
                  <p>{totalTrips}</p>
                </li>
              </ul>
              <h2>Savings</h2>
              <ul>
                <li>
                  <p>By KM rate</p>
                  <p>${totalSavings}</p>
                </li>
                <p>... this doesnt seem right lol</p>
              </ul>
            </div>
          </div>
        </div>
        <table className="entries-table">
          <tbody>
            <tr>
              <th>Date</th>
              <th>Start</th>
              <th>End</th>
              <th>KM</th>
              <th>Reason</th>
              <th>Edit</th>
            </tr>
            {entriesData.length > 0
              ? entriesData.map((entry) => (
                  <tr key={dFT(entry.date)} className="entry-data">
                    <td>{dFT(entry.date)}</td>
                    <td>{entry.startOdometer}</td>
                    <td>{entry.endOdometer}</td>
                    <td>{entry.distance}</td>
                    <td>{entry.reason}</td>
                    <td className="entries-td-icons">
                      <FontAwesomeIcon
                        className="fa"
                        id="fa-trash"
                        icon={faTrash}
                        onClick={() =>
                          handleDeleteEntry(dFT(entry.date), entry.reason)
                        }
                      />
                      <Link to={`/edit/${dFT(entry.date, "link")}`}>
                        <FontAwesomeIcon
                          className="fa"
                          id="fa-pen-to-square"
                          icon={faPenToSquare}
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      {/* TODO: Total distance, business %, kilometre rates & cost  */}
    </div>
  );
};

export default Data;
