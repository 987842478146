import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import "../style/Login_Signup.scss";

const Login = ({ setNotificationData }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn, googleSignIn, user } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await logIn(email, password);
      navigate("/add-entry");
    } catch (err) {
      setNotificationData({ text: err.message, colour: "red" });
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/add-entry");
    } catch (error) {
      console.log(error.message);
    }
  };

  if (user) {
    return <Navigate to="/add-entry" />;
  }

  return (
    <>
      <div className="login-container">
        <h2 className="login-title">Log in</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            id="login-email"
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name="password"
            id="login-password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="login-buttons">
            <button type="submit">Log in</button>
            <p className="decorated">
              <span>Or</span>
            </p>
            <div className="login-google-button">
              <GoogleButton
                className="g-btn"
                type="dark"
                onClick={handleGoogleSignIn}
              />
            </div>
          </div>
        </form>

        <div className="login-to-signup">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </div>
      </div>
    </>
  );
};

export default Login;
