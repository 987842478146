import React, { useState, useEffect } from "react";
// Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// dayjs
import dayjs from "dayjs";
// firebase
import { db } from "../firebase";
import { doc, setDoc, Timestamp } from "firebase/firestore";
// auth
import { useUserAuth } from "../context/UserAuthContext";
import { collection } from "firebase/firestore/lite";

const Dates = ({ flDates, dFT, setflDates, setDatesUpdate }) => {
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const { user } = useUserAuth();
  useEffect(() => {
    if (flDates.first) {
      const f = dFT(flDates.first);
      setFirstDate(f);
    }
    if (flDates.last) {
      const l = dFT(flDates.last);
      setLastDate(l);
    }
  }, [flDates]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewDates();
  };

  const setNewDates = async () => {
    const secondDate = dayjs(dateInput).add(90, "day").toDate();
    let fT = Timestamp.fromDate(dateInput);
    let lT = Timestamp.fromDate(secondDate);

    const datesRef = collection(db, `users/${user.uid}/firstLastDates`);
    await setDoc(doc(datesRef, "first"), { date: fT });
    await setDoc(doc(datesRef, "last"), { date: lT });
    setDatesUpdate(true);
  };

  return (
    <>
      {user.uid && (
        <>
          {firstDate && lastDate ? (
            <div className="dates">
              {firstDate && (
                <h3 className="first-date col">Start date: {firstDate}</h3>
              )}
              {lastDate && (
                <h3 className="last-date col">End date: {lastDate}</h3>
              )}
            </div>
          ) : (
            <form onSubmit={(e) => handleSubmit(e)} id="dates-form">
              <DatePicker
                // shouldCloseOnSelect={true}
                dateFormat="dd/MM/yyyy"
                selected={dateInput}
                onChange={(date) => setDateInput(date)}
              />
              <button id="setDates" type="submit">
                Set start date
              </button>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default Dates;
