import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
// Style
import "../style/Nav.scss";

const Nav = ({ resetValues }) => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <nav className="nav">
      <h1 className="nav-title">Logbook</h1>
      <ul className="nav-list">
        <li className="nav-list-item">
          <NavLink
            to="/add-entry"
            className={({ isActive }) => (isActive ? "active-link" : undefined)}
            onClick={resetValues}
          >
            Add entry
          </NavLink>
        </li>
        <li className="nav-list-item">
          <NavLink
            to="/data"
            className={({ isActive }) => (isActive ? "active-link" : undefined)}
          >
            Data
          </NavLink>
        </li>
        <li className="nav-list-item">
          {user ? (
            <NavLink to="/" end onClick={handleLogout}>
              Log out
            </NavLink>
          ) : (
            <NavLink
              to="/"
              end
              className={({ isActive }) =>
                isActive ? "active-link" : undefined
              }
            >
              Log in
            </NavLink>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
