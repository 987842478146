// React + Router DOM
import React from "react";
import { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// Firebase
import { db } from "../firebase";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { forwardRef } from "react";
// Dates
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// auth
import { useUserAuth } from "../context/UserAuthContext";

const Edit = ({
  setCurrentData,
  setNotificationData,
  startDate,
  setStartDate,
  reasonVal,
  setReasonVal,
  startOdometer,
  setStartOdometer,
  endOdometer,
  setEndOdometer,
  isChecked,
  setChecked,
  dFT,
  resetValues,
}) => {
  const { user } = useUserAuth();
  const [fetchedData, setFetchedData] = useState({});
  const navigate = useNavigate();
  //   isEmpty
  const isEmpty = (obj) => {
    if (obj) {
      return Object.keys(obj).length === 0;
    } else {
      return {};
    }
  };
  // Get doc from page ID
  // Grab ID from URL
  let { page_id } = useParams();
  //   Fetch doc
  useEffect(() => {
    const getEntry = async () => {
      // formate ID/Date
      let newDate = page_id.replace("-", " ");
      newDate = newDate.replace("-", " ");
      const entryRef = doc(db, `users/${user.uid}/entries`, newDate);
      const snapshot = await getDoc(entryRef);
      const data = snapshot.data();
      return data;
    };
    getEntry()
      .then((data) => {
        setFetchedData(data);
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line
  }, [page_id]);

  //   populate state with data
  useEffect(() => {
    if (!isEmpty(fetchedData)) {
      setStartDate(dFT(fetchedData.date, "jsDate"));
      setStartOdometer(fetchedData.startOdometer);
      setEndOdometer(fetchedData.endOdometer);
      setReasonVal(fetchedData.reason);
      setChecked(fetchedData.isChecked);
    }
    // eslint-disable-next-line
  }, [fetchedData]);

  const submitForm = (e) => {
    e.preventDefault();
    if (startOdometer === 0 || endOdometer === 0) {
      console.error("Must input odometer readings");
      setNotificationData({
        text: "Must enter odometer readings",
        colour: "var(--red)",
      });
      return;
    }
    let newDate = page_id.replace("-", " ");
    newDate = newDate.replace("-", " ");
    const dateFormat = newDate;
    let updatedTitle = "";
    if (isChecked === true) {
      updatedTitle = `${dateFormat} (2)`;
    } else {
      updatedTitle = dateFormat;
    }
    const addEntry = async () => {
      let fTimestamp = Timestamp.fromDate(startDate);
      let distance = endOdometer - startOdometer;
      await updateDoc(doc(db, `users/${user.uid}/entries`, updatedTitle), {
        date: fTimestamp,
        startOdometer: startOdometer,
        endOdometer: endOdometer,
        reason: reasonVal,
        distance: distance,
      });
    };
    addEntry();
    resetValues();
    navigate("/data");
  };

  // Custom date picker
  const CustomDate = forwardRef(({ value, onClick }, ref) => (
    <button
      className="custom-date-input btn"
      onClick={onClick}
      ref={ref}
      type="button"
    >
      {value}
    </button>
  ));
  return (
    <>
      {!isEmpty(fetchedData) ? (
        <div className="form-container" id="edit-entry">
          <h1 className="form-title">Edit entry:</h1>
          <form className="form-inputs" onSubmit={(e) => submitForm(e)}>
            <div className="date-input-container tooltip">
              <DatePicker
                // shouldCloseOnSelect={true}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDate />}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <span className="tooltip-text">Date</span>
            </div>
            <div className="start-odometer tooltip">
              <input
                type="number"
                name="start-odometer"
                id="start-odometer"
                className="start-odometer-input"
                value={startOdometer}
                onChange={(e) => setStartOdometer(e.currentTarget.value)}
                placeholder="Start"
                min="0"
              />
              <span className="tooltip-text">Start odometer</span>
            </div>
            <div className="end-odometer tooltip">
              <input
                type="number"
                name="end-odometer"
                id="end-odometer"
                className="end-odometer-input"
                value={endOdometer}
                onChange={(e) => setEndOdometer(e.currentTarget.value)}
                placeholder="End"
                min="0"
              />
              <span className="tooltip-text">End odometer</span>
            </div>
            {/* Distance | Start/end odometer readings */}
            <div className="reason-input-container tooltip">
              <input
                type="text"
                name="reason"
                id="reason"
                value={reasonVal}
                placeholder="Deliveries"
                onChange={(e) => setReasonVal(e.currentTarget.value)}
              />
              <span className="tooltip-text">Journey reason</span>
              {/* Reason | Give text input but auto select: Deliveries (?) */}
            </div>
            <div className="submit-container">
              <div className="second-trip">
                <h3>Second trip today?</h3>
                <input
                  type="checkbox"
                  name="secondTrip"
                  id="secondTrip"
                  onChange={(e) => setChecked(e.currentTarget.checked)}
                />
              </div>
              <div className="buttons">
                <Link className="back-btn" id="edit-back-btn" to="/data">
                  Back
                </Link>
                <button
                  type="submit"
                  name="submit-full"
                  className="form-submit-btn btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="no-entry">
          <h3>No entry available</h3>
          <Link className="back-btn" id="edit-back-btn" to="/data">
            Back
          </Link>
        </div>
      )}
    </>
  );
};

export default Edit;
