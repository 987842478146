import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import "../style/Login_Signup.scss";

const Signup = ({ setNotificationData }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signUp, user } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(email, password);
      navigate("/");
    } catch (err) {
      setNotificationData({ text: err.message, colour: "red" });
    }
  };

  if (user) {
    return <Navigate to="/add-entry" />;
  }

  return (
    <>
      <div className="signup-container">
        <h2 className="signup-title">Signup</h2>
        <form className="signup-form" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            id="signup-email"
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name="password"
            id="signup-password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Sign up</button>
        </form>
        <div className="signup-to-login">
          Already have an account? <Link to="/">Log in</Link>
        </div>
      </div>
    </>
  );
};

export default Signup;
