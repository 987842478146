// React
import { useEffect, useState } from "react";
// Router
import { Routes, Route } from "react-router-dom";
// Firebase
import { doc, setDoc, Timestamp, getDoc, collection } from "firebase/firestore";
import { db, auth } from "./firebase";
// AUTH
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { onAuthStateChanged } from "firebase/auth";
// Components
import Form from "./components/Form";
import Data from "./components/Data";
import Nav from "./components/Nav";
import Edit from "./components/Edit";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
// Style
import "./style/App.scss";
// dayjs
import dayjs from "dayjs";

function App() {
  // Form stuff

  const [currentData, setCurrentData] = useState({});
  const [draftData, setDraftData] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [flDates, setflDates] = useState({});

  // FORM
  const [startDate, setStartDate] = useState(new Date());
  const [reasonVal, setReasonVal] = useState("Deliveries");
  const [startOdometer, setStartOdometer] = useState(null);
  const [endOdometer, setEndOdometer] = useState(0);
  const [isChecked, setChecked] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  // get user
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (u) => {
      // console.log("Auth", currentuser);
      setCurrentUser(u);
    });
    return () => {
      unsub();
    };
  }, []);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  // reset
  const resetValues = () => {
    setStartOdometer(0);
    setEndOdometer(0);
    setStartDate(new Date());
    setReasonVal("Deliveries");
  };

  // DATE FROM TIMESTAMP
  const dFT = (dateInput, format) => {
    if (format === "link") {
      const date = new Timestamp(
        dateInput.seconds,
        dateInput.nanoseconds
      ).toDate();
      const fDate = dayjs(date).format("DD-MM-YY");
      return fDate;
    }
    if (format === "jsDate") {
      const date = new Timestamp(
        dateInput.seconds,
        dateInput.nanoseconds
      ).toDate();
      return date;
    }
    const date = new Timestamp(
      dateInput.seconds,
      dateInput.nanoseconds
    ).toDate();
    const fDate = dayjs(date).format("DD/MM/YY");
    return fDate;
  };

  // Notification
  useEffect(() => {
    if (notificationData.text) {
      setTimeout(() => {
        setNotificationData({});
      }, 6000);
    }
  }, [notificationData]);

  // DATE FROM TIMESTAMP
  const dateFromTimestamp = (dateInput) => {
    const date = new Timestamp(
      dateInput.seconds,
      dateInput.nanoseconds
    ).toDate();
    const fDate = dayjs(date).format("YYYY-MM-DD");
    return fDate;
  };

  // Load draft
  useEffect(() => {
    resetValues();

    const getDraft = async () => {
      const draftRef = doc(
        db,
        `users/${currentUser.uid}/drafts`,
        "draft-entry"
      );
      const snapshot = await getDoc(draftRef);
      if (snapshot.exists()) {
        let data = snapshot.data();
        let date = new Date(dateFromTimestamp(data.date));
        setStartDate(date);
        setStartOdometer(data.startOdometer);
        setEndOdometer(data.endOdometer);
        setReasonVal(data.reason);
      }
    };
    if (currentUser?.uid) {
      getDraft();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  // Add entry
  useEffect(() => {
    if (!isEmpty(currentData)) {
      const data = currentData;
      const dateFormat = dayjs(currentData.startDate).format("DD MM YY");
      let updatedTitle = "";
      if (currentData.isChecked) {
        updatedTitle = `${dateFormat} (2)`;
      } else {
        updatedTitle = dateFormat;
      }
      const addEntry = async () => {
        const userRef = collection(db, `users/${data.uid}/entries`);
        let fTimestamp = Timestamp.fromDate(startDate);
        await setDoc(doc(userRef, updatedTitle), {
          date: fTimestamp,
          startOdometer: data.startOdometer,
          endOdometer: data.endOdometer,
          reason: data.reasonVal,
          distance: data.distance,
        });
      };
      // Check if already exists
      const entryExists = async () => {
        const entryRef = doc(db, `users/${data.uid}/entries`, updatedTitle);
        const snapshot = await getDoc(entryRef);
        const snapshotData = snapshot.data();
        if (snapshotData) {
          setNotificationData({ text: "Entry exists", colour: "red" });
        } else {
          addEntry()
            .then(() => {
              setNotificationData({
                text: "Entry added",
                colour: "var(--green)",
              });

              setStartOdometer(0);
              setEndOdometer(0);
              setStartDate(new Date());
              setReasonVal("Deliveries");
            })
            .catch((err) => console.error(err));
        }
      };
      entryExists();
    }
    //
    //
    //
    //
    //
    //
    //  TO DO: OTHER WORK COSTS (?)
    //
    //
    //
    //
    //
    //
    //
    // eslint-disable-next-line
  }, [currentData]);

  // Draft entry
  useEffect(() => {
    if (!isEmpty(draftData)) {
      const data = draftData;
      const addEntry = async () => {
        let fTimestamp = Timestamp.fromDate(draftData.startDate);
        await setDoc(doc(db, `users/${data.uid}/drafts`, "draft-entry"), {
          date: fTimestamp,
          startOdometer: data.startOdometer,
          endOdometer: data.endOdometer,
          reason: data.reasonVal,
        });
      };
      addEntry()
        .then(() => {
          setNotificationData({
            text: "Entry saved as draft",
            colour: "var(--yellow)",
          });
        })
        .catch((err) => console.error(err));
    }
  }, [draftData]);

  return (
    <div className="App">
      <div className="main-container">
        {/* TODO: replace h1 with navbar */}
        <UserAuthContextProvider>
          <Nav resetValues={resetValues} />
          <Routes>
            <Route
              path="/add-entry"
              element={
                <ProtectedRoute>
                  <Form
                    setCurrentData={setCurrentData}
                    setNotificationData={setNotificationData}
                    setDraftData={setDraftData}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    reasonVal={reasonVal}
                    setReasonVal={setReasonVal}
                    startOdometer={startOdometer}
                    setStartOdometer={setStartOdometer}
                    endOdometer={endOdometer}
                    setEndOdometer={setEndOdometer}
                    isChecked={isChecked}
                    setChecked={setChecked}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="data"
              element={
                <ProtectedRoute>
                  <Data
                    setCurrentData={setCurrentData}
                    setNotificationData={setNotificationData}
                    flDates={flDates}
                    setflDates={setflDates}
                    dFT={dFT}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="edit/:page_id"
              element={
                <ProtectedRoute>
                  <Edit
                    setCurrentData={setCurrentData}
                    setNotificationData={setNotificationData}
                    setDraftData={setDraftData}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    reasonVal={reasonVal}
                    setReasonVal={setReasonVal}
                    startOdometer={startOdometer}
                    setStartOdometer={setStartOdometer}
                    endOdometer={endOdometer}
                    setEndOdometer={setEndOdometer}
                    isChecked={isChecked}
                    setChecked={setChecked}
                    dFT={dFT}
                    resetValues={resetValues}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/"
              element={<Login setNotificationData={setNotificationData} />}
            />
            <Route
              path="/signup"
              element={<Signup setNotificationData={setNotificationData} />}
            />
          </Routes>
        </UserAuthContextProvider>
      </div>
      {notificationData.text ? (
        <div
          style={{ backgroundColor: notificationData.colour }}
          className="notification"
        >
          {notificationData.text}
        </div>
      ) : null}
    </div>
  );
}

export default App;
